import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    fonts: {
    body: 'PT Sans, sans-serif',
    heading: 'Paytone One, sans-serif;',
  },
  initialColorMode: 'dark',
  useSystemColorMode: false,
  }
});

export default theme;
