import React, { useState, useEffect } from 'react';
import { Tooltip } from "@chakra-ui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUp } from '@fortawesome/free-solid-svg-icons';
import { ArrowUpIcon } from '@chakra-ui/icons';

function BTTop() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <Tooltip label='Back To Top' placement='auto-start'>
      <button className={`go-top ${showButton ? 'visible' : 'hidden'}`} onClick={scrollToTop} type='button' style={{border: "1px solid rgb(255 255 255 / 80%)", borderRadius: "25px"}} title='back to top' role='button'><ArrowUpIcon color='rgb(255 255 255 / 80%)' /></button>
      
    </Tooltip>
    
  );
}

export default BTTop;