import { Avatar, Button, Card, CardBody, Heading, Stack, Text } from "@chakra-ui/react";

function Teammember({member}) {
  const {fname, designation, location, image} = member;
  return (
    <Card>
      <CardBody maxW='250px'>
        <Avatar size='2xl' name={fname} src={image} style={{border: "2px solid white"}} />
        <Stack mt={5} spacing={3}>
          <Heading as='h3'>{fname}</Heading>
          <Text>{designation}</Text>
          <Text>{location}</Text>
          {/* <Button colorScheme='teal' size='md'>View Profile</Button> */}
        </Stack>
      </CardBody>
    </Card>
  );
}

export default Teammember;