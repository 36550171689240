import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Heading, Text } from "@chakra-ui/react";

function Services() {
  return (
    <Box minHeight="100vh" p={10} as="section" className="services" id="services" tabindex="0">
      <Box maxWidth="700px"  my={20} mx="auto" textAlign="center" display='flex' flexDirection='column' alignItems='center'>
        <Heading as="h2" tabindex="0">Our Services</Heading>
        <Text mb={2}>From starting right to scaling big profitably,<br/>we've got you covered:</Text>
        <Box display='flex' textAlign='left' gap={5} justifyContent='start' flexWrap='wrap' mt={5} tabindex="0">
          <Box width='250px'>
            <Heading as='h4'>Headless Development</Heading>
          </Box>
          <Box minWidth='350px'>
            <Text><CheckCircleIcon /> Hydrogen/Remix Developement</Text>
            <Text><CheckCircleIcon /> Oxygen deployments</Text>
            <Text><CheckCircleIcon /> Ongoing support and maintenance</Text>
          </Box>
          <hr  style={{width: '100%', color: 'rgb(255 255 255 / 16%'}}/>
        </Box>
        
        <Box display='flex' textAlign='left' gap={5} justifyContent='start' flexWrap='wrap' mt={5} tabindex="0">
          <Box width='250px'>
            <Heading as='h4'>Themes & Adjustments</Heading>
          </Box>
          <Box minWidth='350px'>
            <Text><CheckCircleIcon /> Theme Customizations</Text>
            <Text><CheckCircleIcon /> Custom Theme Development</Text>
          </Box>
          <hr  style={{width: '100%', color: 'rgb(255 255 255 / 16%'}}/>
        </Box>
        <Box display='flex' textAlign='left' gap={5} justifyContent='start' flexWrap='wrap' mt={5} tabindex="0">
          <Box width='250px'>
            <Heading as='h4'>Custom Development</Heading>
          </Box>
          <Box minWidth='350px'>
            <Text><CheckCircleIcon /> Custom Features and functionalities</Text>
            <Text><CheckCircleIcon /> Custom Content Pages and Landing Pages</Text>
            <Text><CheckCircleIcon /> Custom Product Pages</Text>
            <Text><CheckCircleIcon /> Custom Collection Page</Text>
            <Text><CheckCircleIcon /> Custom Sections</Text>
            <Text><CheckCircleIcon /> Custom Header and Footer</Text>
          </Box>
          <hr  style={{width: '100%', color: 'rgb(255 255 255 / 16%'}}/>
        </Box>
        <Box display='flex' textAlign='left' gap={5} justifyContent='start' flexWrap='wrap' mt={5} tabindex="0">
          <Box width='250px'>
            <Heading as='h4'>Web Accessibility & Compliance</Heading>
            <Text><em>This is to ensure you are <strong>ADA (USA), WCAG 2.1, AODA (Canada), Section 508 (USA), EAA (EU), BS 8878 (UK), or ISO/IEC 40500:2012 compliant</strong> depending on where you do business.</em></Text>
          </Box>
          <Box minWidth='350px'>
            <Text><CheckCircleIcon /> Accessibility audits & strategy creation</Text>
            <Text><CheckCircleIcon /> Accessibility Optimization & Compliance<br/>Implementation</Text>
            <Text><CheckCircleIcon /> Ongoing compliance & optimization efforts</Text>
          </Box>
          <hr  style={{width: '100%', color: 'rgb(255 255 255 / 16%'}}/>
        </Box>
        <Box display='flex' textAlign='left' gap={5} justifyContent='start' flexWrap='wrap' mt={5} tabindex="0">
          <Box width='250px'>
            <Heading as='h4'>Optimizations</Heading>
          </Box>
          <Box minWidth='350px'>
            <Text><CheckCircleIcon /> Page Speed Optimization</Text>
            <Text><CheckCircleIcon /> Accessibility Optimization</Text>
            <Text><CheckCircleIcon /> Search Engine Optimization</Text>
            <Text><CheckCircleIcon /> Conversion Rate Optimization</Text>
          </Box>
          <hr  style={{width: '100%', color: 'rgb(255 255 255 / 16%'}}/>
        </Box>
        <Box display='flex' textAlign='left' gap={5} justifyContent='start' flexWrap='wrap' mt={5} tabindex="0">
          <Box width='250px'>
            <Heading as='h4'>Apps & Integrations</Heading>
          </Box>
          <Box minWidth='350px'>
            <Text><CheckCircleIcon /> 3rd party app integrations</Text>
            <Text><CheckCircleIcon /> Checkout Extensions app development</Text>
          </Box>
          <hr  style={{width: '100%', color: 'rgb(255 255 255 / 16%'}}/>
        </Box>
        <Box display='flex' textAlign='left' gap={5} justifyContent='start' flexWrap='wrap' mt={5} tabindex="0">
          <Box width='250px'>
            <Heading as='h4'>Audits</Heading>
          </Box>
          <Box minWidth='350px'>
            <Text><CheckCircleIcon /> Accessibility audits</Text>
            <Text><CheckCircleIcon /> Conversion Rate Optimization audits</Text>
          </Box>
          <hr  style={{width: '100%', color: 'rgb(255 255 255 / 16%'}}/>
        </Box>
        <Box display='flex' textAlign='left' gap={5} justifyContent='start' flexWrap='wrap' mt={5} tabindex="0">
          <Box width='250px'>
            <Heading as='h4'>Support & Troubleshooting</Heading>
          </Box>
          <Box minWidth='350px'>
            <Text><CheckCircleIcon /> Bug fixing</Text>
            <Text><CheckCircleIcon /> Support, maintenance, and training<br/>(one-time and ongoing)</Text>
          </Box>
          <hr  style={{width: '100%', color: 'rgb(255 255 255 / 16%'}}/>
        </Box>
      </Box>
    </Box>
  );
}

export default Services;

// - Subscription::: unlimited and ongoing development and support. Covers all services and more outline in <View Pricing>
// Need more than one service and on-going support, See Subscription Plans.



// Convey the feel of your brand in the first few seconds, make your products easily discoverable, make your customers journey from visit to checkout seamless and smooth, make your upsells a welcome offer and not a nuisance / fantastic customer experiences

// Others
// + Mobile App Development
// + Make A Custom Request

// Resource:
// + Newsletter
// + Book 1
// + Book 2
// + Book 3
// + Free Tool 1
// + Free Tool 2


