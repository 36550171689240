import { ChakraProvider, ColorModeProvider, DarkMode, Box, Button, Image, Link, Tooltip, Text, Heading, Input, Textarea, List, ListItem, ListIcon, Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon, } from "@chakra-ui/react";
import logo from './imgs/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import theme from "./components/ChakraConfig"
import "./custom.css"

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import BTTop from "./components/BackToTop";
import { CheckIcon, CheckCircleIcon } from "@chakra-ui/icons";
import HubspotForm from "./components/ChallengeFormHubspot";



function Header() {
  return(
    <Box className="head" p={10} pt={2} id="head" position='relative' >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Link as="a" href="/"><Image src={logo} title="Logo" alt="BlooAstra Logo" width={180} /></Link>
      </Box>
      <Link href="/" position='absolute' top='7px' textDecoration="none" color="hsl(0deg 0% 100% / 50%)">
        <Tooltip label='Back Home' placement='right' color="hsl(0deg 0% 100% / 50%);" >
          <FontAwesomeIcon icon={faAngleLeft} size='2xl' />
        </Tooltip>
      </Link>
    </Box>
  )
}

function Titles() {
  return(
    <Box className="copy" my={20} maxWidth="600px" mx="auto">
      {/* Heading */}
      <Box className="titles" textAlign='center'>
        <Text as='h1' className="title" fontSize="5xl" bgClip="text" maxW={700} textAlign="center" lineHeight="1em" mb={5} mx='auto' color='white' >Join the 22-Day Shopify Merchant Challenge!</Text>
        <Text className="subtitle" fontSize="2xl" mx='auto' maxW={700} textAlign="center">Free Daily Tasks for Your Shopify Store<br/><em style={{fontSize: "large"}}>From <span style={{textDecoration: "underline"}}>Monday 1st</span> - <span style={{textDecoration: "underline"}}>Wednesday 30th</span>, July, 2024, Mondays to Fridays.</em></Text>
        <Text fontSize="xl" mt={5}>Are you a Shopify merchant looking to enhance your online store? For the next 30 days, I’ll be taking on a task each day to help Shopify merchants like you – <strong style={{textDecoration: "underline"}}>for free!</strong></Text>
        <Button as='a' mt={4} paddingX={10} paddingY={6} href='#form' variant='outline' colorScheme='green'>Submit Task For Free</Button>
      </Box>
    </Box>
  )
}

function Copy() {
  return(
    <Box className="details" my={20} maxWidth="700px" mx="auto" >
      <Box className="howitworks" mt='10em' id="howitworks" padding='2em' background='rgba(0, 0, 0, 0.5)' borderRadius="25px">
        <Heading as="h2" textAlign='center'>How it works:</Heading>

        <Heading as='h4' mt={5}>Step 1:&nbsp;&nbsp;&nbsp;Submit Task Entry</Heading>
        <Text><CheckCircleIcon mx={3} color='green.500' />Fill out the form with a detailed description of the task you need completed.</Text>

        <Heading as='h4' mt={5}>Step 2:&nbsp;&nbsp;&nbsp;Task Review and Breakdown</Heading>
        <Text><CheckCircleIcon mx={2} color='green.500' />We’ll review your submission and, if necessary, break down the description into distinct component tasks.</Text>

        <Heading as='h4' mt={5}>Step 3:&nbsp;&nbsp;&nbsp;Entry and Selection</Heading>
        <Text><CheckCircleIcon mx={2} color='green.500' />Everyday, we'll randomly select one (1) merchant whose task will be tackled. This will be done live and you'll receive a notification email so you can watch it happen. Also, only merchants that haven't been selected will be entered into the selection pool.</Text>

        <Heading as='h4' mt={5}>Step 4:&nbsp;&nbsp;&nbsp;Correspondence and Progress Updates</Heading>
        <Text><CheckCircleIcon mx={2} color='green.500' />If selected, you’ll receive a correspondence detailing the task components and instructions on how to follow progress.</Text>
        
        <Heading as='h4' mt={5}>Step 5:&nbsp;&nbsp;&nbsp;Task Execution</Heading>
        <Text><CheckCircleIcon mx={2} color='green.500' />We'll tackle and complete the tasks efficiently.</Text>
        
        <Heading as='h4' mt={5}>Step 6:&nbsp;&nbsp;&nbsp;Review</Heading>
        <Text><CheckCircleIcon mx={2} color='green.500' />You review the completed tasks and provide feedback.</Text>
        
        <Box textAlign='center' mt={10}><Button as='a' paddingX={10} paddingY={6} href='#form' variant='outline' colorScheme='green'>Submit Task</Button></Box>

      </Box>
      {/* Guidelines */}
      <Box className="guidelines" mt='10em' id="guidelines" padding='2em' background='rgba(0, 0, 0, 0.5)' borderRadius="25px">
        <Heading as="h2" textAlign='center'>Guidelines</Heading>

        <Heading as='h4' mt={5}>Task Submission:</Heading>
        <Text><CheckIcon mx={2} color='green.500' />Fill out the form below to submit your task.</Text>
        
        <Heading as='h4' mt={5}>Store URL Input:</Heading>
        <Text><CheckIcon mx={2} color='green.500' />You can enter your actual domain in the store URL input field. We’ll retrieve your Shopify URL from your metadata.</Text>
        
        <Heading as='h4' mt={5}>Task Details:</Heading>
        <Text><CheckIcon mx={2} color='green.500' />Be as specific as possible when describing the task.</Text>
        
        <Heading as='h4' mt={5}>Design Specifications:</Heading>
        <Text><CheckIcon mx={2} color='green.500' />If you have a design in mind, include the details in the task description. If you have a Figma URL, make sure to include it.</Text>
        <Heading as='h4' mt={5}>Types of Tasks I Can Help With:</Heading>
        <List spacing={1}>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            <strong>Custom Development: </strong>Need a custom section, header, footer, product form, etc?
          </ListItem>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            <strong>Design Tweaks: </strong>Need a small design change?
          </ListItem>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            <strong>SEO Improvements: </strong>Looking to optimize your site for better search rankings?
          </ListItem>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            <strong>App Integration: </strong>Need help integrating a new app into your store?
          </ListItem>
          <ListItem>
            <ListIcon as={CheckIcon} color='green.500' />
            <strong>General Advice: </strong>Have a question or need some guidance on best practices?
          </ListItem>
        </List>

        <Box textAlign='center' mt={10}><Button as='a' paddingX={10} paddingY={6} href='#form' variant='outline' colorScheme='green'>Submit Task</Button></Box>
      </Box>

      <Box className="faqs" mt='10em' id='faqs' padding='2em' background='rgba(0, 0, 0, 0.5)' borderRadius="25px">
        <Heading as="h2" textAlign='center'>Frequently Asked Questions:</Heading>
        <Accordion mt={5}>
          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: How many tasks can I submit?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: You can submit as many as you want but first priority will be given to queue of merchants then queue of tasks.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: How do I submit multiple tasks?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: Simply include them in the same entry. That's what we recommend so we can get to all your tasks as soon as possible.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: What’s the catch?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: There’s no catch! We are doing this to help the Shopify community, create awareness about us, and build our brand.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: How will I know if my task is selected?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: We’ll notify you via email if your task is selected for the day.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: Can I request a specific day for my task?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: Unfortunately, I can’t guarantee specific days for tasks. Tasks will be selected based on various factors including feasibility and impact. But you can reach out to us by <Link href="/#contact" textDecoration='underline' target="_blank"><strong>clicking here</strong></Link> or checking out <Link href="/#services" textDecoration='underline' target="_blank"><strong>our services here</strong> </Link> if you need priority support/assistance.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box textAlign='center' mt={10}><Button as='a' paddingX={10} paddingY={6} href='#form' variant='outline' colorScheme='green'>Submit Task</Button></Box>
      </Box>
    </Box>
  )
}


function Disclaimer() {
  return(
    <Box className="disclaimer" maxWidth='700px' mx='auto' pb={20}>
      <Text><strong>Disclaimer: </strong>Submitting an entry doesn't guarantee that we'll treat it as although we'll try to go through as many entries as come in, we'll need to attend to them on a first-come first-serve basis.</Text>
      <Text>Also, some task details submitted will have to be broken down into separate tasks which means your entry might get treated in batches.</Text>
      <Text>Please read the <Link href="#guidelines" textDecoration='underline'><strong>guidelines</strong></Link> for better understanding.</Text>
    </Box>
  )
}


function Form() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    store: '',
    task: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTimeout(() => {
      emailjs.send(
        'service_wo67jv2',
        'template_4vdkbjc',
        formData,
        'C3C17ZEDmcmpIEaiq',
      ).then((response) => {
        setResponseMessage('Entry submitted successfully!');
        setFormData({ name: '', email: '', phone: '', store: '', task: '', });
        window.location.href="/cropromo";
      }).catch((error) => {
        setResponseMessage('There was an error submitting your entry. Please try again later.');
        setIsSubmitting(false);
      });
    }, 2000);

    
  };
  return(
    <Box minHeight="30vh" p={10} as="section" className="contact" id="form" bgColor="black.500">
      <Box maxWidth="700px"  my={20} mx="auto" textAlign="center" background='rgba(0, 0, 0, 0.5)' padding='3em' borderRadius='25px'>
        <Heading as="h2">Submit Task</Heading>
        <Text fontSize='xl'>Take advantage of this unique opportunity to get free expert help for your Shopify store.<br/>Don’t miss out – submit your task today!</Text>
        <form onSubmit={handleSubmit} style={{margin: "1em 0"}} className="gradient-border" id="sbform">
          <Input placeholder='Your name' size='sm' type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            variant='filled'
            required />
          <Input placeholder="email@domain.com"
            size='md'
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            variant='filled'
            required />
          <Input placeholder="+1 000 000 0000"
            size='md'
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            variant='filled'
            required />
          <Input placeholder='Store URL' size='md' type="text"
            id="store"
            name="store"
            value={formData.store}
            onChange={handleChange}
            variant='filled'
            required />
          <Textarea placeholder='Task details'
            size='md'
            resize='vertical' 
            id="task"
            name="task"
            value={formData.task}
            onChange={handleChange}
            variant='filled'
            required rows={6} />
          <Button variant='solid' colorScheme='green' type="submit" style={{marginTop: "1em"}} isLoading={isSubmitting} loadingText='Submitting'>Submit Task Entry</Button>
          {responseMessage && <p>{responseMessage}</p>}
        </form>
      </Box>
    </Box>
  )
}


function Freetask() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider  colorMode="dark" initialColorMode={theme.config.initialColorMode}>
        <DarkMode>
        <Box mx='auto' backgroundColor='rgba(0, 0, 0, 0.25)' >
          <Header />
          <Titles />
          <Copy />
          {/* <Form /> */}
          <HubspotForm />
          <Disclaimer />
          <BTTop />
        </Box>
        </DarkMode>
      </ColorModeProvider>
    </ChakraProvider>
  );
}

export default Freetask;