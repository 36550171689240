import {  ChakraProvider, ColorModeProvider, DarkMode, Box } from "@chakra-ui/react";

import theme from "./components/ChakraConfig"
import "./custom.css"

function Skip() {
  //get user data from context for customizability
  //lemonsqueezy for upsell
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider  colorMode="dark" initialColorMode={theme.config.initialColorMode}>
        <DarkMode>
          <Box mx='auto' backgroundColor='rgba(0, 0, 0, 0.25)' >
            skip queue and get more done
          </Box>
        </DarkMode>
      </ColorModeProvider>
    </ChakraProvider>
    
  );
}

export default Skip;