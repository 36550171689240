import { Box, Text, Button, HStack, Avatar, Wrap, WrapItem, SimpleGrid, Heading, Image, Flex } from "@chakra-ui/react";
import RegularNav from "../RegularNav";
import Headshot from "../../imgs/headshot.png"
import Shopify from '../../imgs/shopify/shopify.png'
import ShopifyPartner from "../../imgs/shopify/shopify-partners.png"
import ShopifyPlus from '../../imgs/shopify/shopify-plus.png'
import ShopifyPlusExperts from "../../imgs/shopify/shopify-plus-experts.png"


function Hero() {
  return (
    <Box className="hero" p={10} pt={2} mx="auto" as="section" id="hero" display="flex" flexDirection="column" justifyContent="space-between">
      <RegularNav />
      <Box maxW="1200px" my={10} mx="auto" mt='5rem' minHeight="50vh" className="content" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Text className="title"  fontSize="5xl" bgClip="text" color='rgb(255 255 255 / 85%)' maxW={700} textAlign="center" lineHeight="1em" mb={5} >Expert Shopify Developer As And When You Need One</Text>
        <Text className="subtitle" fontSize="2xl" bgClip="text" color='rgb(255 255 255 / 85%)' maxW={700} textAlign="center">I design, develop, and customize beautiful, engaging, user-focused shopping experiences in record time.</Text>
        <HStack>
          <Button as='a' mt={4} paddingX={10} paddingY={6} variant='outline' href='#services'>View Services</Button>
        </HStack>
      </Box>
      <Flex maxW="700px" width='90%' mt={5}className="logos" margin="0 auto" maxWidth='90%' flexDirection='row' wrap='wrap' justifyContent='center' gap={5}>
        <Image src={Shopify} alt="Shopify" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", padding: '5px', height: "50px", borderRadius: '0.7rem'}} />
        <Image src={ShopifyPlus} alt="Shopify Plus" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", padding: '5px', height: "50px", borderRadius: '0.7rem'}} />
        <Image src={ShopifyPartner} alt="Shopify Partners" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", padding: '5px', height: "50px", borderRadius: '0.7rem'}} />
        <Image src={ShopifyPlusExperts} alt="Shopify Plus Experts" style={{backgroundColor: "rgba(255, 255, 255, 0.7)", padding: '5px', height: "50px", borderRadius: '0.7rem'}} />
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing='8' mt="10" p='10' textAlign='center' rounded='lg' >
        <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">Expert.</Heading>
          <Text>I have over a decade of hands-on experience developing solutions for Shopify merchants. Also, I'm a Meta Certified Developer.</Text>
        </Box>
        <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">Full-Time.</Heading>
          <Text>Only handling one client at a time frees me up to focus on and be dedicated to the successful completion of your project.</Text>
        </Box>
        <Box boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0">
          <Heading as="h4">You-Centric.</Heading>
          <Text>Focusing on one client and project at a time allows me to dedicate my time and resources to your project and complete it to your utmost satisfaction.</Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default Hero;