import {  ChakraProvider, ColorModeProvider, DarkMode, Box, Button, Heading, Text, Badge } from "@chakra-ui/react";
import { CheckCircleIcon, ExternalLinkIcon } from "@chakra-ui/icons";

import theme from "./components/ChakraConfig"
import "./custom.css"

function CROAudit() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider  colorMode="dark" initialColorMode={theme.config.initialColorMode}>
        <DarkMode>
          <Box mx='auto' backgroundColor='rgba(0, 0, 0, 0.25)' >
            <Box className="" p={10} pt={2} as="section" id="" display="flex" flexDirection="column" justifyContent="space-between">
              <Box  maxW="1200px" my={10} mx="auto" className="content" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Heading as='h3' className="title"  fontSize="5xl" bgClip="text" color='black.50' maxW={700} textAlign="center" lineHeight="1em" mb={5} >Thanks For Submitting Your Entry</Heading>

                <Text className="" fontSize="xl" bgClip="text" color='black.50' maxW={700} textAlign="center">You'll receive an email confirmation in a bit with details on when the live stream for the raffle will be. In the meantime...</Text>

                <Heading as='h1' className="title"  fontSize="5xl" bgClip="text" color='black.50' maxW={800} textAlign="center" lineHeight="1em" mb={5} mt={10}><span style={{textDecoration: 'underline'}}>Boost Your Sales</span> with a<br/>Professional CRO Audit!</Heading>
                <Text fontSize="2xl" bgClip="text" color='black.50' fontWeight='bold'>Unlock Your Store’s Full Potential with Expert Insights</Text>
                {/* <Text mt={5}>Includes:</Text> */}
                <Box maxW="800px" mx="auto" textAlign='center'><Badge colorScheme="green">Product Page Optimization</Badge> <Badge colorScheme="green">Header Optimization</Badge> <Badge colorScheme="green">Footer Optimization</Badge> <Badge colorScheme="green">Homepage Optimization</Badge> <Badge colorScheme="green">Order flow Optimization</Badge> <Badge colorScheme="green">Collections Page Optimization</Badge> <Badge colorScheme="green">Mobile UI/UX Optimization</Badge> <Badge colorScheme="green">User flow Optimization</Badge> <Badge colorScheme="green">UX/UI Optimization</Badge> <Badge colorScheme="green">...and more</Badge></Box>
              </Box>

              <Box maxW="800px" my={10} mx="auto" className="content" flexDirection="column">
                <Text fontSize="lg">As part of our 15-day challenge, we’re excited to offer you an exclusive opportunity to elevate your Shopify store’s performance even further. For a limited time, you can get a comprehensive Conversion Rate Optimization (CRO) Audit at a special rate - <span style={{ textDecoration: "underline", fontWeight: "bold"}}>$447 off!</span></Text>
                <Heading as="h3" mt={5} mb={2}>Why Consider a CRO Audit?</Heading>
                <Text fontSize="lg">A well-optimized store can make the difference between good sales and great sales. Our CRO Audit dives deep into your store’s user experience, identifying barriers to conversion and opportunities for improvement. With our expert insights, you can turn more visitors into paying customers and increase your revenue.</Text>
                
                <Heading as="h3" mt={5} mb={2}>What’s Included in the CRO Audit?</Heading>
                <Text fontSize="lg"><CheckCircleIcon mx={5} color='green.500' /><strong>Detailed Analysis of User Experience: </strong>We’ll examine your store’s layout, navigation, and overall user journey to ensure a seamless shopping experience.</Text>
                <Text fontSize="lg"><CheckCircleIcon mx={5} color='green.500' /><strong>Performance Review: </strong>An in-depth look at your store’s speed, mobile responsiveness, and checkout process.</Text>
                <Text fontSize="lg"><CheckCircleIcon mx={5} color='green.500' /><strong>Behavior Insights: </strong>Analysis of visitor behavior using heatmaps and session recordings to identify drop-off points.</Text>
                <Text fontSize="lg"><CheckCircleIcon mx={5} color='green.500' /><strong>Actionable Recommendations: </strong>A tailored report with clear, actionable steps to enhance your store’s conversion rates.</Text>
                
                <Heading as="h3" mt={5} mb={2}>Special Offer: Available here only</Heading>
                <Text fontSize="lg">Take advantage of this exclusive offer now and get your CRO Audit at a special discounted rate. This offer is only available during our 15-day challenge, so don’t miss out! Our CRO Audit is priced at $497, you can get it now, on this page <span style={{textDecoration: 'underline'}}>only for $49.99</span>.</Text>
                <Text textDecoration='underline' color='green.200'><a href="https://www.paypal.com/ncp/payment/EV7P9D8RTEHFA" target="_blank" rel="noreferrer">Buy CRO Audit - $447 OFF <ExternalLinkIcon /></a></Text>
              </Box>
              <Box maxW="800px" my={10} mx="auto" className="content" flexDirection="column" textAlign="center">
                <Heading as="h3" mt={5} mb={2}>Transform Your Shopify Store Today!</Heading>
                <Text fontSize="lg">Investing in a CRO Audit can lead to significant improvements in your store’s performance, driving more sales and increasing customer satisfaction. Let’s work together to unlock your store’s full potential!</Text>
                <Button variant='solid' colorScheme='green' type="submit" style={{marginTop: "1em"}}><a href="https://blooastra.lemonsqueezy.com/buy/34f2f6e5-6ba0-4724-af89-b47b62800dd5?embed=1&media=0&logo=0&discount=0" class="lemonsqueezy-button" target="_blank" rel="noreferrer">Buy CRO Audit - Promo Discount ($49.99) <ExternalLinkIcon /></a><script src="https://assets.lemonsqueezy.com/lemon.js" defer></script></Button>
                <Text mt={5}>Includes:</Text>
                <Box><Badge colorScheme="green">Product Page Optimization</Badge> <Badge colorScheme="green">Header Optimization</Badge>  <Badge colorScheme="green">Footer Optimization</Badge> <Badge colorScheme="green">Homepage Optimization</Badge> <Badge colorScheme="green">Order flow Optimization</Badge> <Badge colorScheme="green">Collections Page Optimization</Badge> <Badge colorScheme="green">Mobile UI/UX Optimization</Badge> <Badge colorScheme="green">User flow Optimization</Badge> <Badge colorScheme="green">UX/UI Optimization</Badge> <Badge colorScheme="green">...and more</Badge></Box>
                <Text textDecoration='underline' color='green.200'><a href="https://blooastra.lemonsqueezy.com/buy/34f2f6e5-6ba0-4724-af89-b47b62800dd5?embed=1&media=0&logo=0&discount=0" class="lemonsqueezy-button" target="_blank" rel="noreferrer">Buy Conversion Rate Optimization Audit <ExternalLinkIcon /></a><script src="https://assets.lemonsqueezy.com/lemon.js" defer></script></Text>
              </Box>
            </Box>
          </Box>
        </DarkMode>
      </ColorModeProvider>
    </ChakraProvider>
  );
}

export default CROAudit;