import { Box, Heading, Link, Text, Badge } from "@chakra-ui/react";

function Project({project}) {
  const {id, company, details, url, image, tags} = project;
  return (
    <Box tabindex="0">
      <Box boxShadow='xl' rounded='xl' className="portfolioItem" bgImage={image} border='1px' borderColor='gray.200' position="relative" key={id} borderRadius='15px 15px 0 0' role="img" aria-label="background image">
      </Box>
      <Box className="projectdetails" roundedBottom="xl" py={5} display='flex' flexDirection='column' justifyContent='center' mt='-10px' pt={30} background='black' borderRadius='0 0 15px 15px'>
        {/* <Text fontWeight="bold">Project Details</Text> */}
        <Heading as="h4">{company}</Heading>
        {/* <Text fontSize=".8rem">{details}</Text> */}
        <Text>Tags: 
          {tags.split(',').map((tag, index) => (
             <Badge key={index} colorScheme='green'>{tag}</Badge>
          ))}
        </Text>
        { url ? (<Link as="a" href={url} target="_blank" display="flex">Visit live site <img width="24" height="24" src="https://img.icons8.com/material-sharp/24/ffffff/external-link.png" alt="external-link"/></Link>) : null }
        
      </Box>
    </Box>
  );
}

export default Project;