import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import Liftrose from "../imgs/LiftRose Cosmetics.png";
import Eluna from "../imgs/Eluna Skincare.png";
import Rhinoboy from "../imgs/RhinoBoy Apparel.png";
import Crucifly from "../imgs/www.crucifly.com.jpeg"

import Wavelength from '../imgs/wavelengthswimwear.com.jpeg'
import doubletake from '../imgs/www.shopdoubletake.com.jpeg'
import yiddishwarmth from '../imgs/www.yiddishwarmth.com.jpeg'
import fandrops from '../imgs/fandrops.no.jpeg'
import ridalert from '../imgs/www.ridalert.com.jpeg'
import carboncouture from '../imgs/www.thecarbonculture.com.jpeg'
import upliftfood from '../imgs/upliftfood.com.jpeg'
import everbeam from '../imgs/shopeverbeam.com.jpeg'
import garfield from '../imgs/garfieldcoment.com.jpeg'
import booteca from '../imgs/booteca.com.jpeg'
import ofivo from '../imgs/www.ofivo.com.jpeg'
import steelfit from '../imgs/steelfitusa.com.jpeg'
import shootbrand from '../imgs/shootbrand.net.jpeg'
import a1craft from '../imgs/a1craftandquilting.com.au.jpeg'
import kct from '../imgs/kctmenswear.com.jpeg'

import Project from "./Project";

//Featured Portfolio // Showcase // 6-8 items
function RecentlyCompleted() {
  const projects = [
    {
      "id": 1,
      "company": "Fandrops",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://fandrops.no",
      "image": fandrops,
      "tags": "Custom Theme Development,SEO,Conversion Rate Optimization,Custom Landing Pages"
    },
    {
      "id": 2,
      "company": "Shoot Brand",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://shootbrand.net",
      "image": shootbrand,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 3,
      "company": "Steelfit USA",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://steelfitusa.com",
      "image": steelfit,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 4,
      "company": "Crucifly",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://www.crucifly.com",
      "image": Crucifly,
      "tags": "Theme Modifications,Conversion Rate Optimization,Custom Sections"
    },
    {
      "id": 5,
      "company": "Wavelength Swimwear",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://wavelengthswimwear.com",
      "image": Wavelength,
      "tags": "Theme Modifications,Custom Sections,Custom Features"
    },
    {
      "id": 6,
      "company": "Eluna Skincare",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "",
      "image": Eluna,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 7,
      "company": "Everbeam",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://shopeverbeam.com",
      "image": everbeam,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    // {
    //   "id": 8,
    //   "company": "Rhinoboy",
    //   "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
    //   "url": "",
    //   "image": Rhinoboy,
    //   "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    // },
    {
      "id": 9,
      "company": "Liftrose Skincare",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "",
      "image": Liftrose,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 10,
      "company": "Doubletake",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://www.shopdoubletake.com",
      "image": doubletake,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 11,
      "company": "Yiddish Warmth",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://www.yiddishwarmth.com",
      "image": yiddishwarmth,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 12,
      "company": "Ofivo",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://www.ofivo.com",
      "image": ofivo,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 13,
      "company": "Uplift Foods",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://upliftfood.com",
      "image": upliftfood,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 14,
      "company": "A1 Crafts & Quilting",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://a1craftandquilting.com.au",
      "image": a1craft,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 15,
      "company": "Ridalert",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://www.ridalert.com",
      "image": ridalert,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 16,
      "company": "Carbon Culture",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://www.thecarbonculture.com",
      "image": carboncouture,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 17,
      "company": "Booteca",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://booteca.com",
      "image": booteca,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 18,
      "company": "Garfield",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://garfieldcoment.com",
      "image": garfield,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    },
    {
      "id": 19,
      "company": "KCT Mens Wear",
      "details": "Perks so good you'll never need to go anywhere else for your design. Seriously.",
      "url": "https://kctmenswear.com",
      "image": kct,
      "tags": "Speed Optimization,Conversion Rate Optimization,Theme Modifications,Social Media Integration"
    }
  ];
  return (
    <Box className="portfolio" minHeight="100vh" p={10} py={20} as="section" id="portfolio" display="flex" flexDirection="column" justifyContent="space-between" textAlign="center"  tabindex="0" maxW="1200px" mx="auto">
      <Box>
        <Heading as="h2" tabindex="0">Recent Works</Heading>
        <Text>Our amazing clients have given us the opportunity<br/>to do work we are proud of. </Text>
        {/* <Text className="hide-on-mobile"><em>Hover on image to preview homepage</em></Text> */}
      </Box>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} gap={10} my={10}>
        {
          projects.map((project, index) => {
            // console.log(project);
            if (index < 6) {
              return <Project project={project} key={project.id} />
            }
          })
        }
      </SimpleGrid>
    </Box>
  );
}

export default RecentlyCompleted;

// Image, brief, link to live site