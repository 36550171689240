import React, { useEffect } from 'react';
import { ChakraProvider, ColorModeProvider, DarkMode} from '@chakra-ui/react';

import Hero from './components/Hero';
import RecentlyCompleted from './components/RecentlyCompleted';
import Services from './components/Services';
import Contact from './components/Contact';
import BTTop from './components/BackToTop';

import theme from "./components/ChakraConfig"
import "../src/custom.css"
import About from './components/About';
import Team from './components/Team';
import Testimonial from './components/Testimonial';



export default function Site() {
  function toggleTheme() {
    const htmlElement = document.documentElement;
    const currentTheme = htmlElement.getAttribute('data-theme');
  
    const newTheme = currentTheme === 'light' ? 'dark' : 'dark';
  
    htmlElement.setAttribute('data-theme', newTheme);
    htmlElement.style.colorScheme = newTheme;
  }

  function addAnimation() {
    const scrollers = document.querySelectorAll(".scroller");

    scrollers.forEach((scroller) => {
      scroller.setAttribute("data-animated", true);

      const scrollerInner = scroller.querySelector(".scroller__inner");
      const scrollerContent = Array.from(scrollerInner.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute("aria-hidden", true);
        scrollerInner.appendChild(duplicatedItem);
      });
    });
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      toggleTheme();
      addAnimation();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  return(
    <ChakraProvider theme={theme}>
      <ColorModeProvider  colorMode="dark" initialColorMode={theme.config.initialColorMode} >
        <DarkMode>
          <Hero />
          <About />
          <RecentlyCompleted />
          <Services />
          {/*<FAQ />*/}
          <Team />
          {/* <Testimonial /> */}
          <Contact />
          <BTTop />
        </DarkMode>
      </ColorModeProvider>
    </ChakraProvider>
  )
}