import { Box, Heading, Text } from "@chakra-ui/react";
import CallToAction from "./CTADrawer";

const Contact = () => {
  return (
    <Box minHeight="30vh" p={10} as="section" className="contact" id="contact" bgColor="black.500">
      <Box maxWidth="500px"  my={20} mx="auto" textAlign="center">
        <Heading as="h2">Ready to take the next step?</Heading>
        <Text>Request a quote to initiate contact and we'll reach out to discuss your project and how we can be of service.</Text>
        <CallToAction />
      </Box>
    </Box>
  );
};

export default Contact;
