import { Box, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

const HubspotForm = () => {
  const formContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.charset = 'utf-8';
    script.type = 'text/javascript';

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: '144952575',
          formId: '69563018-b2a3-499f-9e4e-d8bd4b3db5a8',
          target: `#${formContainerRef.current.id}`
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box id="form">
      <Heading as="h2" textAlign="center">Submit Task</Heading>
       <Text fontSize='xl' textAlign="center" mb={5}>Take advantage of this unique opportunity to get free expert help for your Shopify store.<br/>Don’t miss out – submit your task today!</Text>
      <div id="hubspot-form" ref={formContainerRef}></div>
    </Box>
       
  );
};

export default HubspotForm;