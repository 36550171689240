import { useEffect } from "react";
import "./ScrollAnimation.css"

function ScrollAnimation() {
  

  // if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
  //   addAnimation();
  // }


  return (
    <>
    <div className="scroller" data-speed="slow">
      <ul className="tag-list scroller__inner">
        <li>Shopify</li>
        <li>Shopify Plus</li>
        <li>Liquid</li>
        <li>Headless</li>
        <li>Hydrogen</li>
        <li>React</li>
        <li>Remix</li>
      </ul>
    </div>
    </>
  );
}

export default ScrollAnimation;