import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Francis from '../imgs/headshot.png'
import Raphael from '../imgs/raphael.png'
import Jille from '../imgs/jille.png'
import Teammember from "./TeamMember";

function Team() {
  const members = [
    {
      "fname": "Francis",
      "lname": "Okhakumhe",
      "designation": "Senior Shopify Developer, CRO Expert, & Accessibility Specialist",
      "description": "",
      "location": "Lagos, NG",
      "image": Francis,
      'linkedin': '',
      'x': '',
      'youtube': '',
    },
    {
      "fname": "Raphael",
      "lname": "Ola",
      "designation": "Senior Shopify Developer, & CRO Expert",
      "description": "",
      "location": "North Carolina, USA",
      "image": Raphael,
      'linkedin': '',
      'x': '',
      'youtube': '',
    },
    {
      "fname": "Jille",
      "lname": "Irland",
      "designation": "Communications & Language Expert (French, Spanish, Dutch)",
      "description": "",
      "location": "London, UK",
      "image": Jille,
      'linkedin': '',
      'x': '',
      'youtube': '',
    }
  ]
  return (
    <Box className="team" p={10} py={20} as="section" id="team" display="flex" flexDirection="column" justifyContent="space-between" textAlign="center">
      <Box>
        <Heading as="h2">Our Team</Heading>
        <Text>Meet the people who make this possible:</Text>
      </Box>
      <Flex flexDirection='row' wrap='wrap' justifyContent='center' gap={5} mt={10}>
        {
          members.map((member, index) => {
            return <Teammember member={member} key={index} />
          })
        }
      </Flex>
    </Box>
  );
}

export default Team;