import { Box, Heading, Text, Flex, Image, List, ListItem, ListIcon } from "@chakra-ui/react";
import { CheckCircleIcon } from '@chakra-ui/icons';
import Aboutimg from '../imgs/pexels-7971229.jpg'

function About() {
  const videoid = '';
  return (
    <Box className="about" minHeight="100vh" p={10} py={20} as="section" id="about" display="flex" flexDirection="column" justifyContent="center" alignItems='center' textAlign='left' tabindex="0" aria-label="">
      <Box mb={3}>
        <Heading as="h2" tabindex="0">About Us</Heading>
        <Text>A concise introduction:</Text>
      </Box>
      { videoid ? <VideoAbout videoid={videoid} /> : <TextAbout />}
    </Box> 
  );
}

function VideoAbout({videoid}) {
  const videourl = `https://www.youtube.com/embed/${videoid}`;
  return (
    <iframe style={{width: '90%', maxWidth: '750px', height: '30rem', maxHeight: "70vh" }}  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen src={videourl}></iframe>
  )
}

function TextAbout() {
  return(
    <Flex
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="center"
      p={8}
      maxW="1200px"
      mx="auto"
    >
      <Box flex="1" mb={{ base: 8, md: 0 }}>
        <Image
          src={Aboutimg}
          alt="About us"
          borderRadius="md"
          boxShadow="lg"
          maxW="100%"
        />
      </Box>
      <Box flex="1" ml={{ md: 8 }}>
        <Text fontSize="lg" lineHeight="tall" tabindex="0">
          At Blooastra, your goals are our top priority. We blend the agility of freelancers with the reliability of an agency, ensuring your Shopify store succeeds.
        </Text>
        <Text fontSize="lg" lineHeight="tall" mt={2} tabindex="0">With a team of experieced and skilled Shopify developers, we offer faster turnaround times, superior service, and comprehensive solutions tailored to your needs.</Text>
        <Text fontSize="lg" lineHeight="tall" mt={2} fontWeight='bold' tabindex="0">Our streamlined approach ensures:</Text>
        <List spacing={3} fontSize="lg" mt={2}>
          <ListItem tabindex="0">
            <ListIcon as={CheckCircleIcon} color="green.500" />
            Faster turnaround times to keep your projects on track
          </ListItem>
          <ListItem tabindex="0">
            <ListIcon as={CheckCircleIcon} color="green.500" />
            High-quality service and performance to boost your store's success
          </ListItem>
          <ListItem tabindex="0">
            <ListIcon as={CheckCircleIcon} color="green.500" />
            Comprehensive Shopify solutions to meet your specific needs
          </ListItem>
          <ListItem tabindex="0">
            <ListIcon as={CheckCircleIcon} color="green.500" />
            Affordable rates that maximize your return on investment
          </ListItem>
          <ListItem tabindex="0">
            <ListIcon as={CheckCircleIcon} color="green.500" />
            Clear, daily communication to keep you informed and confident
          </ListItem>
        </List>
        <Text  fontSize="lg" lineHeight="tall" mt={5} tabindex="0">Best of all, everything we do for you is <strong>complaint to global and your region-specific accessibility standards</strong> allowing you all the accompanying conversion, usability, and SEO benefits, and you also won't have to worry about getting sued by a user or fined by the government.</Text>
      </Box>
    </Flex>
  )
}

export default About;