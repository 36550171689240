import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import * as ReactDOM from 'react-dom/client';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import Site from './Site'
import Challenge from './Challenge';
import Skip from './Skipqueue';
import CROAudit from './CROpromo';
import Freetask from './FreeTask'

import Personal from './Personal';
import Access from './Accessiblility';

// const container = document.getElementById('root');
// const root = ReactDOM.createRoot(container);

// root.render(
//   <StrictMode>
//     <ColorModeScript initialColorMode='dark' />
//     {/* <App /> */}
//     <Site />
//   </StrictMode>
// );

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Site />} />
      <Route path="/challenge" element={<Challenge />} />
      <Route path="/freetask" element={<Freetask />} />
      <Route path="/queue" element={<Skip />} />
      <Route path="/cropromo" element={<CROAudit />} />
      <Route path='/personal' element={<Personal />} />
      <Route path='/access' element={<Access />} />
      <Route path='/access' element={<Access />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
